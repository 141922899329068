var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-search-results-item"},[_c('div',{staticClass:"nav-search-results-item__wrapper"},[_c('LinkTag',{attrs:{"to":_vm.getRoute},on:{"click":_vm.emitClick}},[_c('div',{staticClass:"nav-search-results-item__link",class:{ 'nav-search-results-item__link--no-image': !_vm.getImage }},[_c('div',{staticClass:"nav-search-results-item__image-holder mr-12"},[_c('div',{staticClass:"nav-search-results-item__image-aspect"},[(_vm.getImage)?_c('bleach-image',{staticClass:"nav-search-results-item__image",attrs:{"src":_vm.getImage.original_secure_url,"sizes":{
                xs: [40, 'auto', 'zoom_1.1']
              },"default-size":[40, 'auto', 'zoom_1.1'],"alt":_vm.getTitle}}):_c('MediaItem',{attrs:{"elementId":'search_default_image'},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(
                    slotProps.mediaItem &&
                      slotProps.mediaItem.image &&
                      slotProps.mediaItem.image[0]
                  )?_c('bleach-image',{staticClass:"nav-search-results-item__image",attrs:{"src":slotProps.mediaItem.image[0].original_secure_url,"sizes":{
                    xs: [40, 'auto', 'zoom_1.1']
                  },"default-size":[40, 'auto', 'zoom_1.1'],"alt":_vm.getTitle}}):_vm._e()]}}])})],1)]),_c('div',{staticClass:"nav-search-results-item__content text-commerce-button-primary text--clr-black text--left"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }