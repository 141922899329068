import GTMAPI from "@/integration/GTMAPI"

import ResultsTitle from "./NavSearchResultsTitle.vue"
import TransitionResults from "./NavigationSearchTransition_Results.vue"

export default {
  props: {
    query: String
  },

  components: {
    ResultsTitle,
    TransitionResults
  },

  methods: {
    handleResultClick(item) {
      GTMAPI.trackEvent("search_result.selected", {
        content_type: item.__typename,
        slug: this.fullSlugFromEntry(item),
        product_id: item.sku,
        product_name: item.displayTitle,
        search_query: this.query
      })
    }
  },

  watch: {
    getMappedHits(newVal) {
      this.$emit("results", newVal)
    }
  },

  computed: {
    getHits() {
      let allHits =
        this.state && this.state.hits
          ? this.state.hits.slice(0, this.maxResults)
          : []
      return allHits
    }
  }
}
