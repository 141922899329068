<template>
  <div class="nav-search-results-item">
    <div class="nav-search-results-item__wrapper">
      <LinkTag :to="getRoute" @click="emitClick">
        <div
          class="nav-search-results-item__link"
          :class="{ 'nav-search-results-item__link--no-image': !getImage }"
        >
          <div class="nav-search-results-item__image-holder mr-12">
            <div class="nav-search-results-item__image-aspect">
              <bleach-image
                v-if="getImage"
                class="nav-search-results-item__image"
                :src="getImage.original_secure_url"
                :sizes="{
                  xs: [40, 'auto', 'zoom_1.1']
                }"
                :default-size="[40, 'auto', 'zoom_1.1']"
                :alt="getTitle"
              />
              <MediaItem v-else :elementId="'search_default_image'">
                <template v-slot:default="slotProps">
                  <bleach-image
                    v-if="
                      slotProps.mediaItem &&
                        slotProps.mediaItem.image &&
                        slotProps.mediaItem.image[0]
                    "
                    class="nav-search-results-item__image"
                    :src="slotProps.mediaItem.image[0].original_secure_url"
                    :sizes="{
                      xs: [40, 'auto', 'zoom_1.1']
                    }"
                    :default-size="[40, 'auto', 'zoom_1.1']"
                    :alt="getTitle"
                  />
                </template>
              </MediaItem>
            </div>
          </div>
          <div
            class="nav-search-results-item__content text-commerce-button-primary text--clr-black text--left"
          >
            {{ getTitle }}
          </div>
        </div>
      </LinkTag>
    </div>
  </div>
</template>

<script>
import { productTypes } from "../../store/constants"
import { mapState } from "vuex"
import ContentfulMixin from "../../contentfulComponents/ContentfulMixin"

import { prefixedSlug } from "@/utils"

export default {
  mixins: [ContentfulMixin],

  methods: {
    emitClick() {
      this.$emit("clicked")
    }
  },

  computed: {
    ...mapState("locale", ["locale"]),

    getTitle() {
      const titleOption =
        this.getContent &&
        ((this.getContent.__typename == productTypes.GIFT_CARD
          ? this.UI_ELEMENT("search_results_item_gift_card")
          : this.getContent.displayTitle) ||
          this.getContent.title ||
          this.getContent.name)

      return typeof titleOption == "string"
        ? titleOption
        : titleOption[this.locale] || titleOption["en-GB"]
    },

    getRoute() {
      let routeObj = {}

      let slugString = prefixedSlug(
        this.getContent.slugPrefix &&
          (this.getContent.slugPrefix["en-GB"] || this.getContent.slugPrefix),
        this.getContent.slug &&
          (this.getContent.slug["en-GB"] || this.getContent.slug)
      )

      if (this.getContent) {
        if (
          [...Object.values(productTypes), "CollectionPage"].includes(
            this.getContent.__typename
          )
        ) {
          routeObj.name = this.getContent.__typename
          if (slugString) {
            routeObj.params = { slug: slugString }
          }
        } else {
          routeObj.path = this.getContent.linkUrl || `/${slugString}`
        }
      }

      return routeObj
    },

    getImage() {
      return (
        this.getContent &&
        ((this.getContent.linkImage &&
          this.getContent.linkImage["en-GB"] &&
          this.getContent.linkImage["en-GB"][0]) ||
          (this.getContent.packshot && this.getContent.packshot[0]) ||
          (this.getContent.image &&
            this.getContent.image["en-GB"] &&
            this.getContent.image["en-GB"][0]) ||
          (this.getContent.image && this.getContent.image[0]) ||
          (this.getContent.images &&
            this.getContent.images["en-GB"] &&
            this.getContent.images["en-GB"][0]) ||
          (this.getContent.images && this.getContent.images[0]))
      )
    }
  }
}
</script>

<style lang="scss">
$result-height-default: 40px;

.nav-search-results-item {
  position: relative;
  flex: 0 0 100%;
  overflow: hidden;
  height: auto;
  width: 100%;
  margin-top: 16px;

  &__wrapper {
    background-color: initial;
    position: relative;
    width: 100%;
    height: auto;
  }

  &__image-holder {
    position: relative;
    flex: 0 0 $result-height-default;
    width: $result-height-default;
    height: $result-height-default;
  }

  &__image-aspect {
    position: relative;
    width: 100%;
    height: $result-height-default;
    background-color: $bleach-grey-80;

    @include media-breakpoint-up(md) {
      height: 0;
      padding-bottom: 100%;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    flex: 1;
    position: relative;
    height: auto;
    min-width: 0;

    @include media-breakpoint-up(md) {
      display: block;
      padding-left: 0;
    }
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__link {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    width: 100%;

    // &--no-image {
    //   align-items: flex-start;
    // }
  }
}
</style>
